import { Skeleton, Typography } from "@mui/material";
import { memo, useCallback, useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { Target } from "../../../redux/Task/TaskApi";
import { useGetProfileQuery } from "../../../redux/User/userApi";
import { getClientById } from "../../../services/Client";

const AssigneeUser = ({
  title = "",
  assigneeUser,
  handleReporterClick = () => {},
  clientId = 0,
  assigneeRole = "",
  target = Target.Self,
}) => {
  const alert = useAlert();

  const [client, setClient] = useState<any>({});
  const [isLoadingClient, setIsLoading] = useState(true);
  const getClient = useCallback(
    async (id) => {
      try {
        const res = await getClientById(id);
        setClient(res?.data);
      } catch (e) {
        alert.error("Error fetching user " + e);
      }
      setIsLoading(false);
    },
    [alert],
  );

  useEffect(() => {
    if (clientId) {
      getClient(clientId);
    }
  }, [clientId, getClient]);

  const {
    data: profile,
    isLoading,
    isError,
  } = useGetProfileQuery(
    {
      userId: assigneeUser,
    },
    {
      skip: !assigneeUser,
    },
  );

  useEffect(() => {
    if (isError) {
      alert.error("Something went wrong fetching User");
    }
  }, [alert, isError]);

  console.log(assigneeUser, target, client, title);

  return (
    <>
      {assigneeUser && isLoading && (
        <Skeleton
          variant="rectangular"
          sx={{
            width: "100px",
            height: "20px",
          }}
        ></Skeleton>
      )}
      {assigneeUser && !isLoading && (
        <Typography variant="body2" component={"span"}>
          {profile?.data?.firstName} {profile?.data?.lastName}
        </Typography>
      )}
      {!assigneeUser && target === Target.Employer && (
        <Typography variant="body2" component={"span"}>
          Assigned to {client?.name}
        </Typography>
      )}
      {!assigneeUser && target === Target.Teamified && (
        <Typography variant="body2" component={"span"}>
          Assigned to {client?.name}
        </Typography>
      )}
      {!assigneeUser && !target && (
        <Typography variant="body2" component={"span"}>
          Assigned to {client?.name} with roles {assigneeRole}
        </Typography>
      )}
      {!assigneeUser && target === Target.Talent && (
        <Typography variant="body2" component={"span"}>
          Assigned to Teamified with roles {assigneeRole}
        </Typography>
      )}
    </>
  );
};

export default memo(AssigneeUser, (prevProps, nextProps) => {
  return prevProps.assigneeUser === nextProps.assigneeUser;
});
