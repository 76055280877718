import { Grid, Pagination } from "@mui/material";
import { memo } from "react";
import TaskTable from "./TaskTable";

const PaginatedTasks = ({
  list,
  refetch,
  totalCount,
  pageNumber,
  onChangePage,
}) => {
  return (
    <Grid
      container
      direction={"column"}
      gap={4}
      pb={2}
      alignItems={"flex-end"}
      justifyContent={"space-between"}
      height={"100%"}
    >
      <TaskTable tasksList={list} refetchTasks={refetch} />
      <Pagination
        count={Math.ceil(totalCount / 10)}
        page={pageNumber}
        onChange={onChangePage}
      />
    </Grid>
  );
};

export default memo(PaginatedTasks);
