import { Grid, Typography } from "@mui/material";
import moment from "moment";
import { memo } from "react";
import { TeamifiedTask } from "../../redux/Task/TaskApi";
import AssigneeUser from "./Components/AssigneeUser";
import PriorityBadge from "./Components/PriorityBadge";
import TaskButton from "./Components/TaskButton";

const TaskRowHeaderDetails = ({ task }: { task: TeamifiedTask }) => {
  const actions = task.actionsJson;
  let parsedActions: any = [];

  if (actions) {
    parsedActions = JSON.parse(actions);
  }
  let action1: any = null;
  let action2: any = null;
  let action3: any = null;

  if (Array.isArray(parsedActions) && parsedActions.length) {
    action1 = parsedActions[0];
    action2 = parsedActions[1];
    action3 = parsedActions[2];
  } else if (
    typeof parsedActions === "object" &&
    Object.keys(parsedActions).length > 0
  ) {
    action1 = parsedActions[0];
  }

  return (
    <Grid container item alignItems={"center"} px={1} spacing={2}>
      <Grid item xs={3}>
        <Typography variant="h5">{task.title}</Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography variant="body1">
          CD:{" "}
          {moment(task.createdAt ? task.createdAt : undefined).format(
            "MM-DD-YYYY",
          )}
        </Typography>
        {task.dueDate && (
          <Typography variant="body1">
            DD: {moment(task.dueDate).format("MM-DD-YYYY")}
          </Typography>
        )}
      </Grid>
      <Grid item xs={2}>
        <AssigneeUser
          title={task.title}
          assigneeUser={task.assigneeUser}
          clientId={task.clientId}
          assigneeRole={task.assigneeRole}
          target={task.target}
          handleReporterClick={() => {}}
        />
      </Grid>
      <Grid item xs={1.5}>
        {task.companyRef}
      </Grid>
      <Grid item xs={1.5}>
        {moment().isAfter(task.dueDate) ? (
          <PriorityBadge
            badgeType={task.priority}
            badgeText="Overdue task"
            colorOverride="rgba(230, 26, 26, 1)"
            textColorOverride="rgba(255, 255, 255, 1)"
          />
        ) : (
          <PriorityBadge badgeType={task.priority} />
        )}
      </Grid>
      {!task.completedAction ? (
        <Grid container item xs={2} gap={1} justifyContent={"flex-end"}>
          {action1 && <TaskButton task={task} action={action1} />}
          {action2 && <TaskButton task={task} action={action2} />}
          {action3 && <TaskButton task={task} action={action3} />}
        </Grid>
      ) : (
        <Grid container item xs={2} gap={1} justifyContent={"flex-end"}>
          <Typography color={"#A16AE8"} fontWeight={500} variant="body1">
            Task Completed
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default memo(TaskRowHeaderDetails);
