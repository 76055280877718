import { Pagination, Skeleton } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { DataGrid } from "@mui/x-data-grid";

import type {} from "@mui/x-data-grid/themeAugmentation";

const theme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        root: {
          border: "none",
          borderRadius: "8px",
          ".MuiDataGrid-columnHeaders": {
            backgroundColor: "#E5EAFD",
            fontSize: "0.875rem",
          },
          ".MuiDataGrid-cell": {
            color: "#000",
            borderBottom: "1px solid #8A969A",
          },
          ".MuiDataGrid-columnSeparator": {
            display: "none",
          },
          ".MuiDataGrid-footerContainer": {
            border: "none",
          },
        },
      },
    },
  },
});

const CustomLoadingOverlay = (
  columns: any,
  numberOfRows: number,
  version: number = 1,
) => {
  if (version === 2) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 10,
          marginTop: 12,
        }}
      >
        {Array.from(new Array(numberOfRows))?.map((_, rowIndex) => (
          <Skeleton key={rowIndex} variant="rounded" height={60} />
        ))}
      </div>
    );
  }

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      {Array.from(new Array(numberOfRows))?.map((_, rowIndex) => (
        <div
          key={rowIndex}
          style={{
            display: "flex",
            gap: 10,
            // borderBottom: "1px solid",
            padding: 10,
          }}
        >
          {columns?.map((column: any) => (
            <Skeleton
              key={column.field}
              variant="rounded"
              sx={{
                width: column.width - 12 || column.minWidth || "auto",
                flex: column.flex || "none",
              }}
              height={34}
            />
          ))}
        </div>
      ))}
    </div>
  );
};

export default function CustomizedDataGrid({
  rows,
  columns,
  isDataLoading = false,
  pageSize = 10,
  totalRowCount = rows.length,
  pageNumber = 0,
  pagination = true,
  selectedRows = [],
  handleRowClick = (a) => {},
  isRowSelectable = (a) => true,
  handlePageChange = (a) => {},
  onPageSizeChange = (a) => {},
  onSelectionModelChange = (a) => {},
  onSortModalChange = (a) => {},
  checkboxSelection = false,
  rowHeight = 55,
  highlightRows = [],
  getRowId = (row) => row.id,
  customClassName = "",
  version = 1,
}) {
  const getRowClassName = (params: any) => {
    //@ts-ignore
    if (highlightRows.includes(params.id.toString())) {
      return "highlight-row";
    }
    return "";
  };

  const CustomNoRowsOverlay = () => {
    return (
      <div className="data-grid-custom-error-msg">
        <h4>No data available</h4>
      </div>
    );
  };

  const CustomFooter = () => {
    return (
      <div className="customize-data-grid-pagination">
        <label>Rows Per Page:</label>
        <Select
          labelId="demo-simple-select-standard-label"
          value={pageSize}
          label="pageSize"
          variant="filled"
          onChange={(e: any) => onPageSizeChange(e.target.value)}
        >
          {Array.from({ length: 10 }, (_, index) => (index + 1) * 10).map(
            (item) => (
              <MenuItem key={item} value={item}>
                {item}
              </MenuItem>
            ),
          )}
        </Select>
        <Pagination
          count={Math.ceil(totalRowCount / pageSize)}
          page={pageNumber}
          onChange={(e: any, page: number) => handlePageChange(page)}
        />
      </div>
    );
  };

  return (
    <ThemeProvider theme={theme}>
      <DataGrid
        className={customClassName}
        rows={rows}
        getRowId={getRowId}
        rowHeight={rowHeight}
        pageSize={pageSize}
        columns={columns}
        loading={isDataLoading}
        page={pageNumber}
        rowCount={totalRowCount}
        paginationMode={"server"}
        sortingMode="server"
        hideFooterSelectedRowCount
        hideFooterPagination={!pagination}
        selectionModel={selectedRows}
        pagination
        checkboxSelection={checkboxSelection}
        disableSelectionOnClick
        onRowClick={handleRowClick}
        onSortModelChange={onSortModalChange}
        onSelectionModelChange={onSelectionModelChange}
        isRowSelectable={isRowSelectable}
        components={{
          Pagination: Pagination,
          LoadingOverlay: () =>
            CustomLoadingOverlay(columns, pageSize, version),
          NoRowsOverlay: () => CustomNoRowsOverlay(),
          ...(pagination && {
            Footer: () => pagination && CustomFooter(),
          }),
        }}
        componentsProps={{
          pagination: {
            count: Math.ceil(totalRowCount / pageSize),
            page: pageNumber + 1,
            onChange: (event, value) => handlePageChange(value - 1),
            pageSize: 10,
          },
        }}
        sx={{
          "& .MuiDataGrid-cell": {
            outline: "none !important",
          },
          ".highlight-row": {
            backgroundColor: "rgba(255, 165, 0, 0.3)",
          },
        }}
        onCellKeyDown={(_, events) => events.stopPropagation()}
        getRowClassName={getRowClassName}
      />
    </ThemeProvider>
  );
}
