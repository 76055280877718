import React, { useMemo } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

//components
import DashboardLanding from "../modules/Dashboard/DashboardLanding/DashboardLanding";
import CompanyName from "../shared/components/CompanyName/CompanyName";
import ForgotPassword from "../shared/components/ForgotPassword/ForgotPassword";
import Info from "../shared/components/Info/Info";
import Login from "../shared/components/Login/Login";
import RegisterUser from "../shared/components/Registration/RegisterUser";
import VerifyRegistration from "../shared/components/VerifyRegisteration/VerifyRegistration";

import ApprovalDetails from "../modules/Approvals/ApprovalDetails";
import Approvals from "../modules/Approvals/Approvals";
import EditClient from "../modules/Client/EditClient";
import ProfileLanding from "../modules/Client/ProfileLanding/ProfileLanding";
import InvoiceList from "../modules/Invoice/InvoiceList";
import EditMember from "../modules/People/EditMember";
import PeopleList from "../modules/People/PeopleList";
import TeamMemberApprovals from "../modules/TeamMemberLeave/TeamMemberApprovals";
import Timesheet from "../modules/TimeSheets/Timesheet";
import TimesheetDetails from "../modules/TimeSheets/TimesheetDetails";
import NotFound from "../shared/components/NotFound/NotFound";

//utils
import Attendance from "../modules/Attendance/Attendance";
import ClientOnboarding from "../modules/Dashboard/ClientOnboarding/ClientOnboarding";
import CandidateListMobile from "../modules/Hire/CandidateListMobile";
import EditJobPost from "../modules/Hire/EditJobPost";
import ClientsInvoices from "../modules/Invoice/ClientsInvoices";
import Opportunities from "../modules/Opportunities/Opportunities";
import OpportunityDetails from "../modules/Opportunities/OpportunityDetails";
import AddMember from "../modules/People/AddMember";
import Performance from "../modules/Performance/Performance";
import AnnualFeedbackDetails from "../modules/Performance/annual/detail/AnnualFeedbackDetails";
import PerformanceDetail from "../modules/Performance/detail/PerformanceDetail";
import PerformanceMultipleFeedback from "../modules/Performance/emailMultipleFeedback/MultipleFeedbackContainer";
import TasksContainer from "../modules/Tasks/TasksContainer";
import Users from "../modules/Users/Users";
import ResetPassword from "../shared/components/ForgotPassword/ResetPassword";
import { PrivateRoute } from "../utils/PrivateRoute/PrivateRoute";
import { usePermissions } from "../utils/permission";

import CandidateListV2 from "../modules/Hire/CandidateListV2";
import HireContainer from "../modules/HireV2/HireContainer";
import NewJobContainer from "../modules/HireV2/newHire/NewJobContainer";
import Shop from "../modules/Shop/Shop";
import TeamMemberWeeklyView from "../modules/TeamMemberTimesheet/TeamMemberTimesheet/TeamMemberTimesheetWeeklyDetail";
import TimesheetTimelogTabs from "../modules/TeamMemberTimesheet/TimesheetTimelogTabs";
import TeammemberDashboard from "../modules/TeammemberDashboard/TeammemberDashboard";

const DocumentsLazy = React.lazy(
  () => import("../modules/Documents/DocumentsContainer"),
);

//Lazy loaded components
const LazyClientLanding = React.lazy(
  () => import("../modules/Client/ClientLanding"),
);

const HireWorkInProgress = React.lazy(
  () => import("../modules/HireV2/HireWorkInProgress"),
);

const Rountes: React.FunctionComponent = () => {
  const permissions = usePermissions();

  const TimeheetView = useMemo(() => {
    return permissions.Team_Member ? <TimesheetTimelogTabs /> : <Timesheet />;
  }, [permissions.Team_Member]);

  const ApprovalView = useMemo(() => {
    return permissions.Team_Member ? <TeamMemberApprovals /> : <Approvals />;
  }, [permissions.Team_Member]);

  const Dashboard = useMemo(() => {
    return permissions.Team_Member ? (
      <TeammemberDashboard />
    ) : (
      <DashboardLanding />
    );
  }, [permissions.Team_Member]);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/Register" element={<RegisterUser />} />
        <Route path="/ConfirmAccount" element={<VerifyRegistration />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/ResetPassword" element={<ResetPassword />} />
        <Route path="/info" element={<Info />} />
        <Route path="/company-name" element={<CompanyName />} />

        <Route
          path="/dashboard"
          element={<PrivateRoute allowedRoute={true}>{Dashboard}</PrivateRoute>}
        />

        <Route
          path="/client-onboarding"
          element={
            <PrivateRoute allowedRoute={permissions.Client}>
              <ClientOnboarding />
            </PrivateRoute>
          }
        />

        <Route
          path="/client"
          element={
            <PrivateRoute allowedRoute={permissions.Client}>
              <LazyClientLanding />
            </PrivateRoute>
          }
        />
        <Route
          path="/edit-client"
          element={
            <PrivateRoute allowedRoute={permissions.Client}>
              <EditClient />
            </PrivateRoute>
          }
        />

        <Route
          path="/profile"
          element={
            <PrivateRoute allowedRoute={true}>
              <ProfileLanding />
            </PrivateRoute>
          }
        />

        <Route
          path="/team-members"
          element={
            <PrivateRoute allowedRoute={permissions.TeamMember}>
              <PeopleList />
            </PrivateRoute>
          }
        />

        <Route
          path="/users"
          element={
            <PrivateRoute allowedRoute={permissions.Users}>
              <Users />
            </PrivateRoute>
          }
        />
        <Route
          path="/add-member"
          element={
            <PrivateRoute allowedRoute={permissions.Users}>
              <AddMember />
            </PrivateRoute>
          }
        />

        <Route
          path="/edit-job"
          element={
            <PrivateRoute allowedRoute={permissions.Hire}>
              <EditJobPost />
            </PrivateRoute>
          }
        />

        <Route
          path="/edit-member"
          element={
            <PrivateRoute allowedRoute={permissions.Users}>
              <EditMember />
            </PrivateRoute>
          }
        />

        <Route
          path="/hire"
          element={
            <PrivateRoute allowedRoute={permissions.Hire}>
              {!permissions.Hire_WIP ? (
                <HireWorkInProgress />
              ) : (
                <HireContainer />
              )}
            </PrivateRoute>
          }
        />
        <Route
          path="/hire/:id"
          element={
            <PrivateRoute allowedRoute={permissions.Hire}>
              {!permissions.Hire_WIP ? (
                <HireWorkInProgress />
              ) : (
                <CandidateListV2 />
              )}
            </PrivateRoute>
          }
        />

        <Route
          path="/hire/new"
          element={
            <PrivateRoute allowedRoute={permissions.Hire}>
              {!permissions.Hire_WIP ? (
                <HireWorkInProgress />
              ) : (
                <NewJobContainer />
              )}
            </PrivateRoute>
          }
        />

        <Route
          path="/candidate-details"
          element={
            <PrivateRoute allowedRoute={permissions.Hire}>
              <CandidateListMobile />
            </PrivateRoute>
          }
        />
        <Route
          path="/leave"
          element={
            <PrivateRoute allowedRoute={permissions.TimeManagement}>
              {ApprovalView}
            </PrivateRoute>
          }
        />

        <Route
          path="/leave/leave-details/:id"
          element={
            <PrivateRoute allowedRoute={permissions.TimeManagement}>
              <ApprovalDetails />
            </PrivateRoute>
          }
        />
        <Route
          path="/timesheets"
          element={
            <PrivateRoute allowedRoute={permissions.TimeManagement}>
              {TimeheetView}
            </PrivateRoute>
          }
        />
        <Route
          path="/timesheets/timesheet-details/:id"
          element={
            <PrivateRoute allowedRoute={permissions.TimeManagement}>
              <TimesheetDetails />
            </PrivateRoute>
          }
        />
        <Route
          path="/timesheets/timesheet-details"
          element={
            <PrivateRoute allowedRoute={permissions.TimeManagement}>
              <TeamMemberWeeklyView />
            </PrivateRoute>
          }
        />
        <Route
          path="/performance"
          element={
            <PrivateRoute allowedRoute={permissions.Performance}>
              <Performance />
            </PrivateRoute>
          }
        />

        <Route
          path="/performance/:id"
          element={
            <PrivateRoute allowedRoute={permissions.Performance}>
              <PerformanceDetail />
            </PrivateRoute>
          }
        />

        {/* Email link feedback: Multiple Employee feedback */}
        <Route
          path="/performance/feedback"
          element={<PerformanceMultipleFeedback />}
        />
        {/* Annual feedback clients and role */}
        <Route
          path="/performance/:id/annual/:year"
          element={
            <PrivateRoute allowedRoute={permissions.Performance}>
              <AnnualFeedbackDetails />
            </PrivateRoute>
          }
        />

        <Route
          path="/tasks"
          element={
            <PrivateRoute allowedRoute={permissions.Task}>
              <TasksContainer />
            </PrivateRoute>
          }
        />

        <Route
          path="/invoices"
          element={
            <PrivateRoute allowedRoute={permissions.Invoice}>
              <InvoiceList />
            </PrivateRoute>
          }
        />

        <Route
          path="/client-invoices"
          element={
            <PrivateRoute allowedRoute={permissions.Invoice}>
              <ClientsInvoices />
            </PrivateRoute>
          }
        />

        <Route
          path="/opportunities"
          element={
            <PrivateRoute allowedRoute={permissions.Opportunity}>
              <Opportunities />
            </PrivateRoute>
          }
        />

        <Route
          path="/opportunities/:id"
          element={
            <PrivateRoute allowedRoute={permissions.Opportunity}>
              <OpportunityDetails />
            </PrivateRoute>
          }
        />
        <Route
          path="/shop"
          element={
            <PrivateRoute allowedRoute={permissions.Shop}>
              <Shop />
            </PrivateRoute>
          }
        />

        <Route
          path="/documents"
          element={
            <PrivateRoute allowedRoute={permissions.Documents}>
              <DocumentsLazy />
            </PrivateRoute>
          }
        ></Route>
        <Route
          path="/attendance"
          element={
            <PrivateRoute allowedRoute={permissions.Attendance}>
              <Attendance />
            </PrivateRoute>
          }
        />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
};
export default Rountes;
