import { userAccess } from "./CommonFunction";

function makeMyObj<T extends Record<string, boolean>>(
  obj: T,
): { [key in keyof T]: T[key] } {
  return obj;
}
export const usePermissions = () => {
  const { role } = userAccess();

  const splitRoleArray = typeof role === "string" ? role?.split(",") : role;

  const isTeamified = splitRoleArray?.includes("Teamified");

  // TMF_Admin and Patona_Admin will be removed at later point
  const isTeamifiedAdmin =
    splitRoleArray?.includes("Teamified_Admin") ||
    splitRoleArray?.includes("TMF_Admin") ||
    splitRoleArray?.includes("Patona_Admin");
  // const isTmfAdmin=splitRoleArray?.includes("TMF_Admin"); not required
  // const isPatonaAdmin=splitRoleArray?.includes("Patona_Admin") not required
  const isTeamifiedDev = splitRoleArray?.includes("Teamified_Developer");

  const isTeamMember = splitRoleArray?.includes("Team_Member");
  const isUser = splitRoleArray?.includes("User");

  // Client_Admin will be removed at later point
  const isEmployer =
    splitRoleArray?.includes("Employer") ||
    splitRoleArray?.includes("Client_Admin");
  const isEmployerHiringManager =
    splitRoleArray?.includes("Employer_Hiring_Manager") ||
    splitRoleArray?.includes("Client_Admin");
  const isEmployerLeaveApprover =
    splitRoleArray?.includes("Employer_Leave_Approver") ||
    splitRoleArray?.includes("Client_Admin");
  const isEmployerFinance =
    splitRoleArray?.includes("Employer_Finance") ||
    splitRoleArray?.includes("Client_Admin");
  const isEmployerPerformanceManager =
    splitRoleArray?.includes("Employer_Performance_Manager") ||
    splitRoleArray?.includes("Client_Admin");

  const Permissions = makeMyObj({
    Team_Member: isTeamMember,
    // clients page features
    Clients_AddNewClient: isTeamified || isTeamifiedAdmin || isTeamifiedDev,
    Clients_ListClients: isTeamified || isTeamifiedAdmin || isTeamifiedDev,
    Clients_ViewClientInfo: !isUser && !isTeamMember,
    Clients_EditClient:
      isTeamified ||
      isTeamifiedAdmin ||
      isTeamifiedDev ||
      isEmployer ||
      isEmployerFinance,

    // Dashboard Page Feature
    Dashboard_Total_Clients: isTeamified || isTeamifiedAdmin || isTeamifiedDev,
    Dashboard_Total_Teammember:
      isTeamified ||
      isTeamifiedAdmin ||
      isEmployer ||
      isEmployerHiringManager ||
      isEmployerFinance ||
      isEmployerPerformanceManager ||
      isEmployerLeaveApprover ||
      isTeamMember,
    Dashboard_JobRequest:
      isTeamifiedAdmin || isEmployer || isEmployerHiringManager || isTeamMember,
    Dashboard_NewJoinees: isTeamified || isTeamifiedAdmin || isTeamifiedDev,
    Dashboard_Invoices:
      isTeamifiedAdmin || isTeamifiedDev || isEmployer || isEmployerFinance,

    // Hire Page Features
    Hire_ListJobs:
      isTeamified ||
      isTeamifiedAdmin ||
      isTeamifiedDev ||
      isEmployer ||
      isEmployerHiringManager ||
      isEmployerLeaveApprover ||
      isEmployerFinance ||
      isEmployerPerformanceManager,

    Hire_AddJobRequest:
      isTeamified || isTeamifiedAdmin || isEmployer || isEmployerHiringManager,
    Hire_ChangeClient: isTeamified || isTeamifiedAdmin || isTeamifiedDev,
    Hire_ChangeRecruiter: isTeamifiedAdmin || isTeamifiedDev,
    Hire_EditCandidateStage: false,
    Hire_AddCandidateActivitycomment:
      isTeamifiedAdmin ||
      isTeamifiedDev ||
      isEmployer ||
      isEmployerHiringManager,
    Hire_SeeCandidateDocument:
      isTeamifiedAdmin ||
      isTeamifiedDev ||
      isEmployer ||
      isEmployerHiringManager,
    Hire_ListInterviewQuestions:
      isTeamifiedAdmin ||
      isTeamifiedDev ||
      isEmployer ||
      isEmployerHiringManager,
    Hire_AddInterviewQuestions:
      isTeamifiedAdmin || isTeamifiedDev || isTeamified,
    Hire_AddInterviewNotes: isTeamifiedAdmin || isTeamifiedDev,
    Hire_SubmitInterviewandEditSummary: isTeamifiedAdmin || isTeamifiedDev,
    Hire_EditInterviewSummary: isTeamifiedAdmin || isTeamifiedDev,
    Hire_UploadCandidateDocuments: isTeamifiedAdmin || isTeamifiedDev,
    Hire_ScheduleMeet: isTeamifiedAdmin || isTeamifiedDev,
    Hire_WIP: isTeamifiedAdmin,
    // Invoice Page Features

    // User Page Features

    User_AddNewInvitation:
      isTeamifiedAdmin ||
      isEmployerHiringManager ||
      isEmployer ||
      isTeamifiedDev,
    User_DisableUser:
      isTeamifiedDev ||
      isTeamifiedAdmin ||
      isEmployer ||
      isEmployerHiringManager,
    User_ListPatona: isTeamified || isTeamifiedDev || isTeamifiedAdmin,
    User_ListClient:
      isTeamified ||
      isTeamifiedDev ||
      isTeamifiedAdmin ||
      isEmployer ||
      isEmployerHiringManager ||
      isEmployerFinance,
    User_ListTeamMembers:
      isTeamified || isTeamifiedDev || isTeamifiedAdmin || isTeamMember,

    // Leave Page Features - Ashu

    TeamMember_AddMember:
      isTeamified ||
      isTeamifiedDev ||
      isTeamifiedAdmin ||
      isEmployer ||
      isEmployerHiringManager,
    TeamMember_EditEmployeeInfo:
      isTeamified || isTeamifiedDev || isTeamifiedAdmin,

    // Opportunities Page Features - Ashu
    Opportunitties_List_Opportunities:
      isTeamifiedDev || isEmployer || isEmployerHiringManager || isTeamMember,
    Opportunitties_ApplyorRefer:
      isTeamifiedDev || isEmployerHiringManager || isTeamMember,

    // Performance Page Features

    Performance_AddReward_Feedback_Action:
      isTeamifiedAdmin ||
      isTeamifiedAdmin ||
      isEmployer ||
      isEmployerPerformanceManager,
    Performance_ViewTeamMemberHistory:
      isTeamifiedAdmin ||
      isTeamifiedDev ||
      isEmployer ||
      isEmployerFinance ||
      isEmployerPerformanceManager,
    Performance_AnnualFeedBack:
      isTeamifiedAdmin ||
      isTeamifiedDev ||
      isEmployer ||
      isEmployerFinance ||
      isEmployerPerformanceManager,

    Profile_EditProfile:
      isTeamified ||
      isTeamifiedAdmin ||
      isTeamifiedDev ||
      isEmployer ||
      isEmployerFinance ||
      isEmployerHiringManager,

    // Leave Page Features - Shrey
    Leave_ApplyLeave:
      isTeamMember || isTeamified || isTeamifiedDev || isTeamifiedAdmin,
    Leave_ApproveOrReject:
      isEmployer ||
      isEmployerLeaveApprover ||
      isTeamifiedAdmin ||
      isTeamifiedDev ||
      isTeamified,

    // Timesheets Page Features - Ashu
    Timesheet_TimeLogTabs: isTeamifiedDev || isTeamMember,
    Timesheet_TimeSheetTabs: isTeamifiedDev || isTeamMember,
    Timesheet_CalendarView: isTeamifiedDev || isTeamMember,
    Timesheet_AddLogTimeLogTimesheet: isTeamifiedDev || isTeamMember,

    Timesheet_ListView: [
      isTeamifiedAdmin,
      isTeamifiedDev,
      isEmployer,
      isEmployerLeaveApprover,
      isEmployerFinance,
    ].some((p) => p),
    Timesheet_ApproveAndRejectTimesheet: [
      isTeamifiedAdmin,
      isTeamifiedDev,
      isEmployer,
      isEmployerLeaveApprover,
      isEmployerFinance,
    ].some((p) => p),

    // Documents Page Features

    Documents_ListPersonalDocs:
      isTeamified ||
      isTeamifiedAdmin ||
      isTeamifiedDev ||
      isEmployer ||
      isEmployerHiringManager ||
      isEmployerLeaveApprover ||
      isEmployerPerformanceManager ||
      isEmployerFinance ||
      isTeamMember,

    Documents_ListTeamMemberDocs: false,

    Documents_ListClientDocs:
      isTeamifiedAdmin ||
      isTeamifiedDev ||
      isEmployer ||
      isEmployerHiringManager ||
      isEmployerLeaveApprover ||
      isEmployerPerformanceManager ||
      isEmployerFinance ||
      isTeamMember,

    // Performance Page Features

    // Tasks Page Features

    Task_DeleteTask: isTeamifiedAdmin || isTeamifiedDev,
    Task_AddComments:
      isTeamified ||
      isTeamifiedAdmin ||
      isTeamifiedDev ||
      isEmployer ||
      isEmployerHiringManager ||
      isEmployerFinance ||
      isEmployerLeaveApprover ||
      isEmployerPerformanceManager ||
      isTeamMember,
    Task_AddTask:
      isTeamified ||
      isTeamifiedAdmin ||
      isTeamifiedDev ||
      isEmployer ||
      isEmployerHiringManager ||
      isEmployerFinance ||
      isEmployerPerformanceManager ||
      isEmployerLeaveApprover,

    Task_ListAllTask:
      isTeamifiedAdmin ||
      isTeamifiedDev ||
      isEmployer ||
      isTeamified ||
      isEmployerFinance ||
      isEmployerHiringManager ||
      isEmployerLeaveApprover ||
      isEmployerPerformanceManager,

    Task_AddTaskTeamified: isTeamified || isTeamifiedAdmin || isTeamifiedDev,
    Task_ShowEmployerSelection:
      isTeamified || isTeamifiedAdmin || isTeamifiedDev,

    //Navigation

    Hire:
      isTeamified ||
      isTeamifiedAdmin ||
      isTeamifiedDev ||
      isEmployer ||
      isEmployerHiringManager ||
      isEmployerFinance,

    TeamMember:
      isTeamified ||
      isTeamifiedAdmin ||
      isTeamifiedDev ||
      isEmployer ||
      isEmployerHiringManager ||
      isTeamMember ||
      isEmployerFinance ||
      isEmployerLeaveApprover ||
      isEmployerPerformanceManager,
    Client: isTeamifiedAdmin || isTeamifiedDev || isTeamified,
    Users:
      isTeamified ||
      isTeamifiedAdmin ||
      isTeamifiedDev ||
      isEmployer ||
      isEmployerHiringManager ||
      isEmployerFinance,
    Opportunity: isTeamMember,

    Performance:
      isTeamifiedAdmin ||
      isTeamifiedDev ||
      isEmployer ||
      isEmployerFinance ||
      isEmployerPerformanceManager ||
      isTeamMember,
    Task:
      isTeamified ||
      isTeamifiedAdmin ||
      isTeamifiedDev ||
      isEmployer ||
      isEmployerHiringManager ||
      isEmployerLeaveApprover ||
      isEmployerFinance ||
      isEmployerPerformanceManager ||
      isTeamMember,
    Invoice:
      isTeamifiedAdmin || isTeamifiedDev || isEmployerFinance || isEmployer,
    Shop:
      isTeamifiedAdmin ||
      isTeamifiedDev ||
      isTeamified ||
      isEmployer ||
      isEmployerFinance ||
      isEmployerHiringManager,
    Attendance: isTeamMember,
    Documents:
      isTeamified ||
      isTeamifiedAdmin ||
      isTeamifiedDev ||
      isEmployer ||
      isEmployerHiringManager ||
      isEmployerLeaveApprover ||
      isEmployerFinance ||
      isEmployerPerformanceManager ||
      isTeamMember,

    TimeManagement:
      isEmployer ||
      isTeamifiedAdmin ||
      isTeamifiedDev ||
      isEmployerLeaveApprover ||
      isEmployerFinance ||
      isTeamMember,
  });

  return Permissions;
};
