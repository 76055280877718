import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  getJobRequestList,
  getJobRequestListFromPatona,
  getJobRequestTypes,
} from "../../services/HireV2";
import Layout from "../../shared/components/LayoutV2";
import HireHeader from "./HireHeader";
import HireList from "./HireList";
import "./index.scss";

const HireContainer = () => {
  const navigate = useNavigate();
  const [requestTypes, setRequestTypes] = useState([]);
  const [selectedRequestType, setSelectedRequestType] = useState("Open");

  const [jobRequestList, setJobRequestList] = useState<any>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");

  const { clientCode } = useSelector(
    //@ts-ignore
    (state) => state.client,
  );

  const fetchRequestTypes = useCallback(async () => {
    // Fetch request types from "Recruitly API"
    const data = await getJobRequestTypes();

    const newRequestTypes = [
      ...data.data,
      // This is specific to requests raised by Patona, the Job Requests are stored in Patona's DB
      {
        id: "Raised",
        name: "Raised",
      },
    ] as any;

    setRequestTypes(newRequestTypes);
  }, []);

  useEffect(() => {
    fetchRequestTypes();
  }, [fetchRequestTypes]);

  const fetchRequests = useCallback(async () => {
    setJobRequestList([]);
    setIsLoading(true);

    if (selectedRequestType === "Raised") {
      // This is specific to requests raised by Patona, the Job Requests are stored in Patona's DB
      const data = await getJobRequestListFromPatona({
        pageNumber,
        pageSize,
        searchTerm,
      });

      setIsLoading(false);
      setJobRequestList(data?.reqTypeFilteredData);
      setTotalCount(data?.paginationFilter?.count);
      return;
    }

    const payload = {
      requestType: selectedRequestType,
      pageNumber,
      pageSize,
      searchTerm,
    };
    const data = await getJobRequestList(payload);

    setIsLoading(false);
    setJobRequestList(data?.filteredJobs);
    setTotalCount(data?.paginationFilter?.count);
  }, [pageNumber, pageSize, searchTerm, selectedRequestType]);
  useEffect(() => {
    setPageNumber(1);
    fetchRequests();
  }, [selectedRequestType, searchTerm, pageSize, clientCode, fetchRequests]);

  useEffect(() => {
    fetchRequests();
  }, [fetchRequests, pageNumber]);

  const onSearch = (inputTerm: string) => {
    setSearchTerm(inputTerm);
  };

  const handlePageChange = (paramPageNumber: number) => {
    setPageNumber(paramPageNumber);
  };

  const handlePageSizeChange = (paramPageSize: number) => {
    setPageSize(paramPageSize);
  };

  const handleMenuClick = (action: string, row: any) => {
    switch (action) {
      case "edit":
        navigate(`/hire/${row.job_Id}`);
        break;
      case "clone":
        const isPatonaRaised = selectedRequestType === "Raised";
        navigate(`/hire/new`, {
          state: { jobId: row.job_Id, isPatonaRaised: isPatonaRaised },
        });
        break;
      case "cancel":
        console.log("Close clicked", row);
        break;
      case "assign":
        console.log("Close clicked", row);
        break;
      default:
        break;
    }
  };

  return (
    <>
      <Layout
        header={
          <HireHeader
            requestTypes={requestTypes}
            setSelectedRequestType={setSelectedRequestType}
            onSearch={onSearch}
          />
        }
        body={
          <HireList
            jobRequestList={jobRequestList}
            isLoading={isLoading}
            totalCount={totalCount}
            pageSize={pageSize}
            pageNumber={pageNumber}
            handlePageChange={handlePageChange}
            handlePageSizeChange={handlePageSizeChange}
            handleMenuClick={handleMenuClick}
            selectedRequestType={selectedRequestType}
          />
        }
      />
    </>
  );
};

export default HireContainer;
