import { createApi } from "@reduxjs/toolkit/dist/query/react";
import axiosBaseQuery from "../../services/service";
const BASE_URL = process.env.REACT_APP_BASE_API_URL;

function providesList<R extends { id: string | number }[], T extends string>(
  resultsWithIds: R | undefined,
  tagType: T,
) {
  return resultsWithIds
    ? [
        { type: tagType, id: "LIST" },
        ...resultsWithIds.map(({ id }) => ({ type: tagType, id })),
      ]
    : [{ type: tagType, id: "LIST" }];
}

type GetMyTaskRequest = {
  priority?: string;
  status?: string;
  userId: string;
  startDate?: string;
  endDate?: string;
  pageNumber: number;
  pageSize: number;
  searchValue?: string;
  clientId: number;
};

type GetTaskRoleRequest = {
  priority?: string;
  status?: string;
  role: string;
  startDate?: string;
  endDate?: string;
  pageNumber: number;
  pageSize: number;
  searchValue?: string;
  clientId: number;
};

export enum AssignmentTypes {
  client = "client",
  role = "role",
  individual = "employee",
  account_manager = "account_manager",
}

export enum Target {
  Employer = "client",
  Teamified = "teamified",
  Self = "selfindividual",
  TeamMember = "individual",
  Talent = "talent",
}

export enum BadgeType {
  URGENT = "urgent",
  MEDIUM = "medium",
  LOW = "low",
}

export type TeamifiedTask = {
  id: number;
  taskType: "individualTask" | "workflowTask";
  title: string;
  summary: string;
  assignTo: number;
  assigneeRole: string;
  workflowActorId: string;
  jobId: null | string;
  candidateId: null | string;
  clientId: number;
  assigneeUser: null | string;
  assignedAt: string;
  actionsJson: string;
  completedAction: null | string;
  completedBy: null | string;
  completedAt: null | string;
  priority: BadgeType;
  createdAt: string;
  updatedAt: string;
  dueDate: null | string;
  companyRef: number | null;
  target: Target;
  assigneeUserEmail: string;
};

type GetTaskRoleResponse = {
  data: {
    paginationFilter: {
      pageNumber: number;
      pageSize: number;
      count: number;
    };
    tasks: Array<TeamifiedTask>;
  };
  message: string;
  successful: boolean;
  status: number;
};

type AddTaskRequest = {
  assigneeRole: string;
  assigneeUser: null | string;
  clientId: number;
  createdBy: string;
  priority: "Low" | "Medium" | "Urgent";
  summary: string;
  taskType: "individualTask";
  title: string;
  assigneeUserEmail: string;
  creatorUserEmail: string;
  target: Target;
};

type AddTaskResponse = {
  data: { succeeded: boolean; errors: Array<string> };
  message: string;
  successful: boolean;
  status: number;
};

type AddTaskCommentRequest = {
  comment: string;
  id: number;
  taskId: number;
  commentedBy: string;
  commentedByEmail: string;
};

type AddTaskCommentResponse = {
  data: { succeeded: boolean; errors: Array<string> };
  message: string;
  successful: boolean;
  status: number;
};

type GetTaskByCreatorIdRequest = {
  priority?: string;
  status?: string;
  createdBy: string;
  startDate?: string;
  endDate?: string;
  pageNumber: number;
  pageSize: number;
  searchValue?: string;
  clientId: number;
};

type DeleteTaskRequest = {
  taskId: number;
};

type GetTaskByIdRequest = {
  taskId: number;
};

type Comment = {
  id: number;
  comment: string;
  commentedBy: string;
  commentedByEmail: string;
  taskId: number;
  createdAt: string;
  updatedAt: null | string;
};

type TaskExtraField = {
  taskId: number;
  taskType: "individualTask" | "workflowTask";
  title: string;
  summary: string;
  jobId: null | string;
  candidateId: null | string;
  clientId: string;
  workflowActorId: null | string;
  assigneeUser: null | string;
  assigneeRole: string;
  assignedAt: string;
  actionsJson: string;
  completedAction: null | string;
  completedBy: null | string;
  completedAt: null | string;
  completedRemarks: null | string;
  priority: "Low" | "Medium" | "Urgent";
  createdAt: string;
  createdBy: string;
  updatedAt: null | string;
  dueDate: null | string;
  target: string;
  creatorUserEmail: string;
  assigneeUserEmail: string;
};

type GetTaskByIdResponse = {
  data: {
    comments: Array<Comment>;
    task: TaskExtraField;
  };
  message: string;
  successful: boolean;
  status: number;
};

export const TaskApi = createApi({
  reducerPath: "task",
  baseQuery: axiosBaseQuery({
    baseUrl: BASE_URL || "",
  }),
  tagTypes: ["TaskList", "TaskComment"],
  endpoints: (builder) => ({
    getTasks: builder.query<GetTaskRoleResponse, GetTaskRoleRequest>({
      query: (req) => ({
        url: "/GetAllTasksByRole",
        method: "POST",
        body: req,
      }),
      providesTags: (result) => providesList(result?.data.tasks, "TaskList"),
    }),
    getMyTasks: builder.query<GetTaskRoleResponse, GetMyTaskRequest>({
      query: (req) => ({
        url: "/GetMyAllTasks",
        method: "POST",
        body: req,
      }),
      providesTags: (result) => providesList(result?.data.tasks, "TaskList"),
    }),
    addTask: builder.mutation<AddTaskResponse, AddTaskRequest>({
      query: (req) => ({
        url: "addTask",
        method: "POST",
        body: req,
      }),
      invalidatesTags: ["TaskList"],
    }),
    addTaskComment: builder.mutation<
      AddTaskCommentResponse,
      AddTaskCommentRequest
    >({
      query: (req) => ({
        url: "addTaskComment",
        method: "POST",
        body: req,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "TaskComment", id: arg.taskId },
      ],
    }),
    getTaskByCreatorId: builder.query<
      GetTaskRoleResponse,
      GetTaskByCreatorIdRequest
    >({
      query: (req) => ({
        url: "GetAllTasksByCreatorUserId",
        method: "POST",
        body: req,
      }),
      providesTags: (result) => providesList(result?.data.tasks, "TaskList"),
    }),
    updateTask: builder.mutation<AddTaskCommentResponse, TeamifiedTask>({
      query: (req) => ({
        url: "updateTask",
        body: req,
        method: "POST",
      }),
      invalidatesTags: ["TaskList"],
    }),
    deleteTask: builder.mutation<AddTaskCommentResponse, DeleteTaskRequest>({
      query: ({ taskId }) => ({
        url: "deleteTask",
        body: { taskId },
        method: "POST",
      }),
      invalidatesTags: ["TaskList"],
    }),
    getTaskById: builder.query<GetTaskByIdResponse, GetTaskByIdRequest>({
      query: (req) => ({
        url: "GetTaskById",
        method: "POST",
        body: req,
      }),
      providesTags: (result) => [
        { type: "TaskComment", id: result?.data.task.taskId },
      ],
    }),
  }),
});

export const {
  useAddTaskCommentMutation,
  useAddTaskMutation,
  useDeleteTaskMutation,
  useUpdateTaskMutation,
  useGetTaskByCreatorIdQuery,
  useGetTaskByIdQuery,
  useGetTasksQuery,
  useGetMyTasksQuery,
} = TaskApi;
