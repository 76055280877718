import {
  Box,
  Button,
  Container,
  Grid,
  Pagination,
  Skeleton,
  Typography,
} from "@mui/material";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Search from "../../shared/components/Search/Search";
import { STRINGS } from "../../Strings";
import { usePermissions } from "../../utils/permission";
import ApplyJobModal from "./ApplyJobModal";
import { getOpportunities } from "./opportunities.helper";
import OpportunityFilter from "./OpportunityFilter";
import ReferModal from "./ReferModal";
import { useStyles } from "./style";

const Opportunities = ({ dashboard }: any) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const navigate = useNavigate();
  const { Opportunitties_ApplyorRefer } = usePermissions();

  const [pageCount, setPageCount] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [openApply, setOpenApply] = useState(false);
  const [openRefer, setOpenRefer] = useState(false);
  const [jobDetails, setJobDetails] = useState({});
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [activeMenu, setActiveMenu] = useState<any | "">("");
  const [activePeriodOption, setActivePeriodOption] = useState<any | "">("");
  const [searchJob, setSearchJob] = useState("");

  const open = Boolean(anchorEl);

  const { jobs, jobsLoading } = useSelector(
    //@ts-ignore
    (state) => state.opportunities,
  );

  const handleApplyOpenClose = useCallback(() => {
    setOpenApply((prev) => !prev);
  }, []);

  const handleReferOpenClose = useCallback(() => {
    setOpenRefer((prev) => !prev);
  }, []);

  const handleFilterClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    },
    [],
  );

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handlePeriodClick = useCallback(() => {
    setActivePeriodOption("");
    setActiveMenu((prev) => (prev === "custom" ? "" : "custom"));
  }, []);

  const handleMainMenuItemClick = useCallback(
    (menu: any) => {
      if (menu === activeMenu) {
        setActiveMenu("");
      } else {
        setActiveMenu(menu);
        setActivePeriodOption("");
      }
      setPage(1);
    },
    [activeMenu],
  );

  const handlePeriodMenuItemClick = useCallback(
    (option: any) => {
      if (option === activePeriodOption) {
      } else {
        setActivePeriodOption(option);
        setActiveMenu("custom");
      }
      setPage(1);
    },
    [activePeriodOption],
  );

  const handlePageCountChange = useCallback((_, newPageCount) => {
    setPage(newPageCount);
  }, []);

  const getJobDetails = useCallback((data) => {
    setJobDetails(data);
  }, []);

  const filterOpportunityPayload = useMemo(() => {
    let OpportunityFromDate = "";
    let OpportunityToDate = "";

    if (activePeriodOption === "this month") {
      OpportunityFromDate = moment().startOf("month").toISOString();
      OpportunityToDate = moment().endOf("month").toISOString();
    } else if (activePeriodOption === "this week") {
      OpportunityFromDate = moment().startOf("week").toISOString();
      OpportunityToDate = moment().endOf("week").toISOString();
    } else if (activePeriodOption === "quarterly") {
      OpportunityFromDate = moment().startOf("quarter").toISOString();
      OpportunityToDate = moment().endOf("quarter").toISOString();
    } else if (activePeriodOption === "annual") {
      OpportunityFromDate = moment().startOf("year").toISOString();
      OpportunityToDate = moment().endOf("year").toISOString();
    }

    return {
      PageNumber: page.toString(),
      PageSize: !dashboard ? "6" : "3",
      Company_Name: "",
      Job_Title: "",
      SearchCompanyTitle: searchJob,
      Period: activePeriodOption ? activePeriodOption : "",
      IsDesc: activeMenu && activeMenu !== "custom" ? activeMenu : false,
      OpportunityFromDate,
      OpportunityToDate,
    };
  }, [activePeriodOption, page, dashboard, searchJob, activeMenu]);

  useEffect(() => {
    if (activeMenu !== "custom" || activePeriodOption) {
      getOpportunities(dispatch, filterOpportunityPayload);
    }
  }, [filterOpportunityPayload, dispatch, activeMenu, activePeriodOption]);

  useEffect(() => {
    if (jobs?.paginationFilter) {
      const initialCount = Math.ceil(
        jobs?.paginationFilter?.count / jobs?.paginationFilter?.pageSize,
      );
      setPageCount(initialCount);
    }
  }, [jobs?.paginationFilter]);

  const getSearch = (input: string) => {
    setSearchJob(input);
  };

  return (
    <Container maxWidth="xl">
      {!dashboard && (
        <Grid container sx={{ pt: 2 }} justifyContent={"space-between"}>
          <Grid item xs={4}>
            <Search
              placeHolder="Search by client or job title"
              getSearch={getSearch}
            />
          </Grid>
          <Grid item>
            <Button variant="contained" onClick={handleFilterClick}>
              {STRINGS.Filter}
            </Button>
            <OpportunityFilter
              open={open}
              anchorEl={anchorEl}
              // setAnchorEl={setAnchorEl}
              handleClose={handleClose}
              handlePeriodClick={handlePeriodClick}
              handleMainMenuItemClick={handleMainMenuItemClick}
              handlePeriodMenuItemClick={handlePeriodMenuItemClick}
              activeMenu={activeMenu}
              activePeriodOption={activePeriodOption}
            />
          </Grid>
        </Grid>
      )}
      {!jobsLoading ? (
        <Grid container spacing={2} sx={{ mt: 0.5 }}>
          {jobs.filteredJobs?.map((item, index) => (
            <Grid
              item
              sm={4}
              md={4}
              key={index}
              onClick={() => {
                navigate(`/opportunities/${item?.job_Id}`);
              }}
              sx={{ cursor: "pointer" }}
            >
              <Box className={classes.cardHeader}>
                <Typography ml={2} fontWeight={500} fontSize={"16px"}>
                  {item.company_Name}
                </Typography>
              </Box>
              <div className={classes.card}>
                <div className={classes.cardDetail}>
                  <Typography>Job Title - {item.job_Title}</Typography>
                  <div
                    className={classes.container}
                    dangerouslySetInnerHTML={{ __html: item.description }}
                  />
                </div>
                {Opportunitties_ApplyorRefer && (
                  <Grid>
                    <Button
                      variant="contained"
                      sx={{ mt: 2 }}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleApplyOpenClose();
                        getJobDetails(item);
                      }}
                      disabled={Opportunitties_ApplyorRefer ? false : true}
                    >
                      {STRINGS.ApplyJob}
                    </Button>
                    <Button
                      variant="outlined"
                      sx={{ mt: 2, ml: 1 }}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleReferOpenClose();
                        getJobDetails(item);
                      }}
                      disabled={Opportunitties_ApplyorRefer ? false : true}
                    >
                      {STRINGS.Refer}
                    </Button>
                  </Grid>
                )}
              </div>
            </Grid>
          ))}

          {!dashboard && (
            <Grid
              item
              sm={12}
              md={12}
              display="flex"
              alignItems="center"
              justifyContent="center"
              mb={2}
            >
              {pageCount > 0 ? (
                <Pagination
                  count={pageCount}
                  page={page}
                  onChange={handlePageCountChange}
                  size="large"
                  color="primary"
                />
              ) : (
                <Typography>
                  No jobs available for this filter, Please try other filter
                </Typography>
              )}
            </Grid>
          )}
        </Grid>
      ) : (
        <Grid container spacing={3}>
          {Array.from({ length: 6 }).map((_, index) => (
            <Grid item sm={4} md={4} key={index}>
              <Skeleton variant="text" sx={{ fontSize: "4rem" }} />
              {Array.from({ length: 7 }).map((_, idx) => (
                <Box display="flex" justifyContent="space-between" key={idx}>
                  <Skeleton
                    variant="text"
                    width={"150px"}
                    sx={{ fontSize: "1rem" }}
                  />
                  <Skeleton
                    variant="text"
                    width={"150px"}
                    sx={{ fontSize: "1rem" }}
                  />
                </Box>
              ))}
              <Skeleton variant="text" sx={{ fontSize: "2rem" }} />
            </Grid>
          ))}
        </Grid>
      )}
      <ApplyJobModal
        handleOpenClose={handleApplyOpenClose}
        open={openApply}
        jobDetails={jobDetails}
      />
      <ReferModal
        handleOpenClose={handleReferOpenClose}
        open={openRefer}
        jobDetails={jobDetails}
      />
    </Container>
  );
};

export default Opportunities;
