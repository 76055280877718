import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useLocation, useNavigate } from "react-router-dom";
import { useStyles } from "./styles";

import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { verifyRegistrationSchema } from "../../../schemas";

//styles
import { CircularProgress } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { clearRegistration } from "../../../redux/Registration/userRegistrationSlice";
import { registerProps } from "../../../Types/Users/User";
import { getToken, registerUserAction } from "./VerifyRegisteration.helper";

//To get the query params
const useQuery = () => {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
};

const VerifyRegistration = () => {
  const { isRegisterLoading } = useSelector(
    //@ts-ignore
    (state) => state.registration,
  );
  const [initialValues, setInitialValues] = useState<
    registerProps & {
      fetchRole: string;
      tokenId: string;
    }
  >({
    firstName: "",
    lastName: "",
    companyName: "",
    email: "",
    role: "",
    roles: "",
    roleId: "",
    operation: "CLIENT_ADMIN_INVITE",
    clientID: "",
    isAllAccess: true,
    createdBy: "",
    fetchRole: "",
    Password: "",
    ConfirmPassword: "",
    autoSignIn: true,
    tokenId: "",
    uId: "",
  });
  let query = useQuery();
  const guid = query.get("ref");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const alert = useAlert();
  const classes = useStyles();
  const [isTokenLoading, setIsLoading] = React.useState<boolean>(false);

  const [firstName, setFirstName] = useState("User");

  useEffect(() => {
    setIsLoading(true);
    getToken(guid, alert, navigate).then(
      (decoded: {
        email: string;
        clientId: string;
        company: string;
        role: string;
        firstname: string;
        lastname: string;
        createdBy: string;
        roles: string;
      }) => {
        setInitialValues({
          email: decoded.email,
          clientID: decoded.clientId,
          companyName: decoded.company,
          role: decoded.role,
          firstName: decoded.firstname,
          lastName: decoded.lastname,
          roleId: "",
          uId: decoded.createdBy,
          roles: decoded.roles,
          isAllAccess: true,
          autoSignIn: true,
          tokenId: guid?.toString() || "",
          fetchRole: "",
        });
        setFirstName(decoded.firstname);
        setIsLoading(false);
      },
    );
  }, [guid]);

  const handleEmailChange = (event: any) => {};

  useEffect(() => {
    dispatch(clearRegistration());
  }, [dispatch]);

  const {
    values,
    errors,
    touched,
    dirty,
    handleBlur,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: verifyRegistrationSchema,
    enableReinitialize: true,
    onSubmit: (values, action) => {
      const confirmPassword: { ConfirmPassword?: string } = {
        ConfirmPassword: values.ConfirmPassword,
      };
      delete confirmPassword["ConfirmPassword"];
      registerUserAction(values, dispatch, navigate, alert);
      action.resetForm();
    },
  });

  return (
    <>
      <Container maxWidth="xl" disableGutters={true}>
        <Grid
          container
          component="main"
          spacing={0}
          direction="row"
          alignItems="center"
          justifyContent="center"
          style={{ minHeight: "100vh" }}
        >
          <Grid item sm={4} md={6}>
            <Box
              component="img"
              src="/images/Teamified-Logo-Black.svg"
              className="logo_rbl"
              sx={{
                maxWidth: "-webkit-fill-available",
                padding: {
                  md: "24px 0px 0px 43px",
                  sm: "24px 0px",
                },
              }}
              alt="Teamified logo"
            />
            <Box sx={{ display: { xs: "none", sm: "block" } }}>
              <img src="/images/signup-banner.svg" alt="sign up" />
            </Box>
          </Grid>

          <Grid item xs={12} sm={8} md={6} className={classes.right_column}>
            <Box sx={{ display: "grid" }}>
              <Box>
                <Typography variant="h3" align="center" mb={2}>
                  Welcome {firstName}
                </Typography>
                <Typography variant="body1" align="center" mb={3}>
                  Grow your team the right way with Teamified
                </Typography>
              </Box>
              <Box>
                <Box
                  sx={{
                    display: "block",
                    maxWidth: "480px",
                    margin: "auto",
                    pointerEvents:
                      isTokenLoading || isRegisterLoading ? "none" : "auto",
                  }}
                  component="form"
                  noValidate
                  onSubmit={handleSubmit}
                >
                  <TextField
                    disabled
                    margin="normal"
                    fullWidth
                    id="Email"
                    label="Email"
                    name="Email"
                    variant="outlined"
                    autoComplete="off"
                    value={values.email}
                    onChange={handleEmailChange}
                  />
                  <TextField
                    error={Boolean(touched.Password && errors.Password)}
                    helperText={touched.Password && errors.Password}
                    margin="normal"
                    fullWidth
                    type="password"
                    id="Password"
                    label="Password"
                    name="Password"
                    variant="outlined"
                    autoComplete="off"
                    value={values.Password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <TextField
                    error={Boolean(
                      touched.ConfirmPassword && errors.ConfirmPassword,
                    )}
                    helperText={
                      touched.ConfirmPassword && errors.ConfirmPassword
                    }
                    margin="normal"
                    fullWidth
                    type="password"
                    id="ConfirmPassword"
                    label="Confirm Password"
                    name="ConfirmPassword"
                    variant="outlined"
                    autoComplete="off"
                    value={values.ConfirmPassword}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <Button
                    disabled={!dirty}
                    type="submit"
                    fullWidth
                    size="large"
                    variant="contained"
                    sx={{ mt: 2 }}
                  >
                    {(isTokenLoading || isRegisterLoading) && (
                      <CircularProgress size={18} color="warning" />
                    )}
                    {(!isTokenLoading || !isRegisterLoading) && "Confirm"}
                  </Button>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default VerifyRegistration;
