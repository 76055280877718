import {
  Autocomplete,
  Box,
  Button,
  Grid,
  lighten,
  styled,
  TextField,
} from "@mui/material";
import { Form, Formik } from "formik";
import { FC, memo, useCallback, useEffect, useMemo, useState } from "react";
import { useAlert } from "react-alert";
import { useSelector } from "react-redux";
import * as yup from "yup";
import {
  BadgeType,
  Target,
  TeamifiedTask,
  useAddTaskMutation,
  useUpdateTaskMutation,
} from "../../redux/Task/TaskApi";
import { useGetOnlyAvailableRolesAndUsersForClientQuery } from "../../redux/User/userApi";
import FormikInput from "../../shared/components/FormikInput";
import { userAccess } from "../../utils/CommonFunction";
import { usePermissions } from "../../utils/permission";

const GroupHeader = styled("div")(({ theme }) => ({
  position: "sticky",
  padding: "4px 10px",
  color: theme.palette.primary.main,
  backgroundColor: lighten(theme.palette.primary.light, 0.85),
}));

const GroupItems = styled("ul")({
  padding: 0,
});

const validationSchema = yup.object({
  title: yup.string().required("Task title is required"),
  summary: yup.string().required("Summary is required"),
  priority: yup.string().required("Please select a priority"),
  dueDate: yup.string().required("Due date is required"),
  target: yup.string().required("Assign to field is required"),
});

type AssignmentOptionType = {
  name: string;
  assigneeUser: string | null;
  assigneeRole: string;
  id: string;
  target: string;
  assigneeEmail?: string;
};

type Props = {
  closeModal: () => void;
  task?: TeamifiedTask;
};

const OPTIONS_CLIENT = [
  { name: "Teamified", value: Target.Teamified },
  { name: "Self", value: Target.Self },
  { name: "Team Member", value: Target.TeamMember },
];
const OPTIONS_TEAMIFIED = [
  { name: "Employer", value: Target.Employer },
  { name: "Teamified", value: Target.Teamified },
  { name: "Self", value: Target.Self },
  { name: "Team Member", value: Target.TeamMember },
];

const AddNewTaskModal: FC<Props> = ({ closeModal, task }) => {
  let defaultInitialValues = useMemo(() => {
    if (task)
      return {
        assigneeRole: task.assigneeRole,
        taskType: task.taskType,
        title: task.title,
        summary: task.summary,
        clientId: task.clientId,
        client: task.clientId,
        priority: task.priority,
        createdAt: task.createdAt,
        assigneeUser: task.assigneeUser,
        companyRef: task.companyRef,
        target: task.target,
        dueDate: task.dueDate,
      };

    return {
      taskType: "individualTask",
      title: "",
      summary: "",
      clientId: "",
      priority: "",
      createdAt: "",
      companyRef: null,
      assigneeRole: "",
      target: null,
      dueDate: "",
    };
  }, [task]);

  const { Task_ShowEmployerSelection } = usePermissions();

  const assignmentValueOptions = useMemo(() => {
    return Task_ShowEmployerSelection ? OPTIONS_TEAMIFIED : OPTIONS_CLIENT;
  }, [Task_ShowEmployerSelection]);

  const [initialValues, setInitialValues] = useState(defaultInitialValues);
  const [clientId, setClientId] = useState<number>(userAccess().ClientId);
  const { data: rolesData } = useGetOnlyAvailableRolesAndUsersForClientQuery(
    {
      clientId: clientId,
    },
    {
      refetchOnMountOrArgChange: true,
    },
  );

  const [assignmentValue, setAssignmentValue] =
    useState<AssignmentOptionType | null>(null);

  useEffect(() => {
    if (assignmentValue === null && task) {
      setTarget(task.target);

      if (
        (task.target === Target.Employer || task.target === Target.Teamified) &&
        task.assigneeUser
      ) {
        setAssignmentValue({
          assigneeRole: task.assigneeRole,
          assigneeUser: task.assigneeUser,
          id: task.assigneeUser,
          name: "",
          target: "User",
          assigneeEmail: task.assigneeUserEmail,
        });
      } else if (
        (task.target === Target.Employer || task.target === Target.Teamified) &&
        !task.assigneeUser
      ) {
        setAssignmentValue({
          assigneeRole: task.assigneeRole,
          assigneeUser: null,
          id: task.assigneeRole,
          name: task.assigneeRole,
          target: "Role",
        });
      } else if (task.target === Target.TeamMember) {
        if (userAccess().userId === task.assigneeUser) {
          //@ts-ignore
          setInitialValues((prev) => ({
            ...prev,
            target: Target.Self,
          }));
        }

        setAssignmentValue({
          assigneeRole: task.assigneeRole,
          assigneeEmail: task.assigneeUserEmail,
          assigneeUser: task.assigneeUser,
          id: task.assigneeUser || "",
          name: "",
          target: "Team Member",
        });
      }
    }
  }, [assignmentValue, task]);

  const [target, setTarget] = useState<Target | null>(null);
  useEffect(() => {
    if (target === Target.Teamified) {
      setClientId(999);
    } else {
      setClientId(userAccess().ClientId);
    }
  }, [target]);

  const [
    addTaskApi,
    {
      isLoading: addTaskLoading,
      isError: addTaskError,
      isSuccess: addTaskSuccess,
      error,
    },
  ] = useAddTaskMutation();
  const [
    updateTaskApi,
    {
      isLoading: updateTaskLoading,
      isError: updateTaskError,
      isSuccess: updateTaskSuccess,
      error: updateError,
    },
  ] = useUpdateTaskMutation();
  const { allWorkableClients, isLoading } = useSelector(
    //@ts-ignore
    (state) => state.hire,
  );
  const resetToDefaultValues = useCallback(() => {
    if (task && defaultInitialValues.target) {
      setInitialValues(defaultInitialValues);
    }
  }, [defaultInitialValues, task]);

  const resetOrCancel = useCallback(
    (resetForm: () => void) => () => {
      if (!task) {
        closeModal?.();
      }
      resetToDefaultValues();
      resetForm();
    },
    [task, resetToDefaultValues, closeModal],
  );

  useEffect(() => {
    if (task) {
      resetToDefaultValues();
    }
  }, [task, resetToDefaultValues]);
  const alert = useAlert();

  useEffect(() => {
    if (addTaskSuccess) {
      closeModal();
      alert.success("Task added successfully");
    }
  }, [addTaskSuccess, alert, closeModal]);

  useEffect(() => {
    if (addTaskError && error) {
      alert.error("Error adding task ");
    }
  }, [addTaskError, alert, error]);

  useEffect(() => {
    if (updateTaskSuccess) {
      alert.success("Task updated successfully");
    }
  }, [updateTaskSuccess, alert]);

  useEffect(() => {
    if (updateTaskError && updateError) {
      alert.error("Error adding task ");
    }
  }, [updateTaskError, alert, updateError]);

  const postAddTask = useCallback(
    async (values) => {
      const request = {
        title: values.title,
        summary: values.summary,
        priority: values.priority,
        taskType: values.taskType,
        dueDate: values.dueDate,
        assigneeRole: values.assigneeRole,
        companyRef: values.companyRef,
        assigneeUser: values.assigneeUser,
        clientId: values.clientId || 999,
        createdBy: userAccess().userId,
        creatorUserEmail: userAccess().email,
        assigneeUserEmail: values.assigneeUserEmail,
        target:
          values.target === Target.Self ? Target.TeamMember : values.Target,
      };

      addTaskApi(request);
    },
    [addTaskApi],
  );

  const clientOptions = useMemo(() => {
    return [{ name: "None", value: null }].concat(
      allWorkableClients.map((item) => {
        return { name: item.name, value: item.id };
      }),
    );
  }, [allWorkableClients]);

  const clientOptionsRef = useMemo(() => {
    return [{ name: "None", value: null }].concat(
      allWorkableClients.map((item) => {
        return { name: item.name, value: item.name };
      }),
    );
  }, [allWorkableClients]);

  const updateTask = useCallback(
    async (values) => {
      if (task) {
        const request = {
          ...task,
          title: values.title,
          summary: values.summary,
          priority: values.priority,
          taskType: values.taskType,
          dueDate: values.dueDate,
          assigneeRole: values.assigneeRole,
          companyRef: values.companyRef,
          assigneeUser: values.assigneeUser,
          clientId: values.clientId || 999,
          assigneeUserEmail: values.assigneeUserEmail,
          target: values.target,
        };
        updateTaskApi(request);
      }
    },
    [task, updateTaskApi],
  );

  const autoCompleteOptions = useMemo(() => {
    const options: Array<AssignmentOptionType> = [];

    if (target !== Target.TeamMember) {
      rolesData?.data.roleList.forEach((role) => {
        options.push({
          assigneeRole: role,
          assigneeUser: null,
          id: role,
          name: role,
          target: "Role",
        });
      });

      rolesData?.data.userList.forEach((user) => {
        options.push({
          assigneeRole: user.roles.join(","),
          assigneeUser: user.uId,
          id: user.uId,
          name: user.firstName,
          target: "User",
          assigneeEmail: user.emailAddress,
        });
      });
    } else if (target === Target.TeamMember && userAccess().client) {
      rolesData?.data.userList.forEach((user) => {
        options.push({
          assigneeRole: user.roles.join(""),
          assigneeUser: user.uId,
          id: user.uId,
          name: user.firstName,
          target: "User",
          assigneeEmail: user.emailAddress,
        });
      });
      rolesData?.data.teamMembersList.forEach((user) => {
        options.push({
          assigneeRole: "Team_Member",
          assigneeUser: user.uId,
          id: user.uId,
          name: user.firstName,
          target: "Team Member",
          assigneeEmail: user.emailAddress,
        });
      });
    } else if (target === Target.TeamMember) {
      rolesData?.data.teamMembersList.forEach((user) => {
        options.push({
          assigneeRole: "Team_Member",
          assigneeUser: user.uId,
          id: user.uId,
          name: user.firstName,
          target: "Team Member",
          assigneeEmail: user.emailAddress,
        });
      });
    }

    return options;
  }, [rolesData, target]);

  const onChangeAutoComplete = useCallback(
    (formikProps) => (_, v: AssignmentOptionType | null) => {
      setAssignmentValue(v);
      formikProps.setFieldValue("assigneeUser", v?.assigneeUser);
      formikProps.setFieldValue("assigneeRole", v?.assigneeRole);
      formikProps.setFieldValue("assigneeUserEmail", v?.assigneeEmail);
    },
    [],
  );

  console.log(assignmentValue);
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize
      onSubmit={(data, { resetForm }) => {
        console.log("add called");
        if (task) {
          updateTask(data);
        } else {
          postAddTask(data).then(() => resetForm());
        }
      }}
    >
      {(formikProps) => (
        <Form
          style={{
            flex: 1,
          }}
        >
          <Grid container spacing={2} mt={1}>
            <Grid item xs={12} md={12}>
              <FormikInput
                label="Task*"
                name="title"
                formikProps={formikProps}
                options={undefined}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <FormikInput
                label="Describe the task*"
                name="summary"
                formikProps={formikProps}
                options={undefined}
                multiline
                rows={3}
                fullWidth
              />
            </Grid>
            <Grid container item xs={12} gap={1}>
              <Grid item style={{ flex: 1 }}>
                <FormikInput
                  label="Assign type"
                  name="target"
                  className="common-select-dropdown"
                  formikProps={formikProps}
                  options={assignmentValueOptions}
                  schemaChange
                  schemaOnChangeFun={(value) => {
                    setTarget(value);
                    formikProps.setFieldValue("clientId", 0);
                    formikProps.setFieldValue("target", value);
                    if (value === Target.Self) {
                      formikProps.setFieldValue(
                        "assigneeUser",
                        userAccess().userId,
                      );

                      formikProps.setFieldValue(
                        "assigneeRole",
                        "placeholderRole",
                      );
                      formikProps.setFieldValue(
                        "assigneeUserEmail",
                        userAccess().email,
                      );
                    }
                    if (
                      (value === Target.TeamMember || value === Target.Self) &&
                      userAccess().client
                    ) {
                      formikProps.setFieldValue(
                        "clientId",
                        userAccess().ClientId,
                      );
                      setClientId(userAccess().ClientId);
                    }
                  }}
                  inputType="select"
                  fullWidth
                />
              </Grid>

              {!userAccess().client && (
                <Grid item style={{ flex: 1 }}>
                  {formikProps.values.target === Target.Employer ||
                  formikProps.values.target === Target.TeamMember ? (
                    <FormikInput
                      label="Client*"
                      name="client"
                      className="common-select-dropdown"
                      formikProps={formikProps}
                      options={clientOptions}
                      schemaChange
                      schemaOnChangeFun={(value) => {
                        setClientId(value);
                        formikProps.setFieldValue("clientId", value);
                      }}
                      inputType="select"
                      fullWidth
                    />
                  ) : (
                    <FormikInput
                      label="In reference to"
                      name="companyRef"
                      className="common-select-dropdown"
                      formikProps={formikProps}
                      options={clientOptionsRef}
                      schemaChange
                      schemaOnChangeFun={(value) => {
                        formikProps.setFieldValue("compantRef", value);
                      }}
                      inputType="select"
                      fullWidth
                    />
                  )}
                </Grid>
              )}
              {formikProps.values.target !== Target.Self && (
                <Grid item style={{ flex: 1 }}>
                  <Autocomplete
                    onChange={onChangeAutoComplete(formikProps)}
                    options={autoCompleteOptions || []}
                    groupBy={(option) => `Assign to ${option.target}`}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                      <TextField {...params} label="Assign to" />
                    )}
                    value={assignmentValue}
                    renderGroup={(params) => (
                      <li key={params.key}>
                        <GroupHeader>{params.group}</GroupHeader>
                        <GroupItems>{params.children}</GroupItems>
                      </li>
                    )}
                  />
                </Grid>
              )}

              <Grid item style={{ flex: 1 }}>
                <FormikInput
                  label="Priority*"
                  name="priority"
                  className="common-select-dropdown"
                  formikProps={formikProps}
                  options={[
                    { name: "Low", value: BadgeType.LOW },
                    { name: "Medium", value: BadgeType.MEDIUM },
                    { name: "Urgent", value: BadgeType.URGENT },
                  ]}
                  inputType="select"
                  fullWidth
                />
              </Grid>
              <Grid item style={{ flex: 1 }}>
                <FormikInput
                  label="Due date*"
                  name="dueDate"
                  formikProps={formikProps}
                  options={null}
                  inputType="date-picker"
                  inputFormat="MM/DD/YYYY"
                  disablePast
                  fullWidth
                />
              </Grid>
            </Grid>
          </Grid>

          <Box display="flex" justifyContent="flex-end" sx={{ mt: 3, mb: 2 }}>
            <Button
              variant="text"
              sx={{ mt: 2 }}
              onClick={resetOrCancel(formikProps.resetForm)}
            >
              {task ? "Reset" : "Cancel"}
            </Button>
            <Button
              type="submit"
              disabled={isLoading || addTaskLoading || updateTaskLoading}
              variant="contained"
              sx={{ mt: 2 }}
            >
              {task ? "Update task" : "Add task"}
            </Button>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default memo(AddNewTaskModal);
