import { Grid, Typography } from "@mui/material";
import { FC, memo } from "react";
import { BadgeType } from "../../../redux/Task/TaskApi";

const BADGE_BG_COLOR: Record<BadgeType, string> = {
  urgent: "#FDE0D0",
  medium: "#FEEFCE",
  low: "#FFF3D1",
};

const BADGE_TEXT_COLOR: Record<BadgeType, string> = {
  urgent: "#E61A1A",
  medium: "#F27F0D",
  low: "#FFC315",
};

const BADGE_TEXT: Record<BadgeType, string> = {
  urgent: "Urgent",
  medium: "Medium",
  low: "Low",
};

type PriorityBadgeProps = {
  badgeType: BadgeType;
  colorOverride?: string;
  textColorOverride?: string;
  badgeText?: string;
};

const PriorityBadge: FC<PriorityBadgeProps> = ({
  badgeType,
  colorOverride = "",
  textColorOverride = "",
  badgeText = "",
}) => {
  return (
    <Grid
      container
      item
      sx={{
        backgroundColor: colorOverride
          ? colorOverride
          : BADGE_BG_COLOR[badgeType],
        justifyContent: "center",
        paddingY: "10px",
        borderRadius: "10px",
      }}
    >
      <Typography
        sx={{
          color: textColorOverride
            ? textColorOverride
            : BADGE_TEXT_COLOR[badgeType],
          fontWeight: 500,
          fontSize: "14px",
          lineHeight: "20px",
        }}
      >
        {badgeText ? badgeText : BADGE_TEXT[badgeType]}
      </Typography>
    </Grid>
  );
};

export default memo(PriorityBadge);
