import Box from "@mui/material/Box";
import React, { Suspense } from "react";
import { useSelector } from "react-redux";
import SideNav from "./sidenav";

export const DefaultLayout = ({ children }) => {
  const { isClientLoginFirstTime } = useSelector(
    //@ts-ignore
    (state) => state.user,
  );
  return (
    <Suspense fallback="Loading...">
      <Box sx={{ pointerEvents: isClientLoginFirstTime ? "none" : "auto" }}>
        <SideNav />
      </Box>
      <Box component="main" className="main">
        {children}
      </Box>
    </Suspense>
  );
};
